<template>
  <div id="app">
    <div :class="classLoader" ref="loader" v-if="loading_actif">
      <div class="loading_inner">
        <div class="block_inner font_bebas">
          <div class="cache_loader cache_top"></div>
          <div class="cache_loader cache_bottom"></div>
          <div class="cache_loader cache_left"></div>
          <div class="cache_loader cache_right"></div>
          <a class="loader_jpg" ref="loader_jpg"><img class="loader_jpg_img" :src="require('@/assets/images/ui/loader.jpg')" />
            <span class="bordure_bg bordure_top"></span>
            <span class="bordure_bg bordure_right"></span>
            <span class="bordure_bg bordure_bottom"></span>
            <span class="bordure_bg bordure_left"></span>
            <span class="bordure_anim bordure_anim_top"></span>
            <span class="bordure_anim bordure_anim_right"></span>
            <span class="bordure_anim bordure_anim_bottom"></span>
            <span class="bordure_anim bordure_anim_left"></span>
          </a>
        </div>
      </div>
    </div>
    <div class="navigation">
      <div class="nav_left">
        <a @click="loadPageFromMenu('/')" class="font_bebas logo_crazy">crazymage</a>
        <a @click="loadPageFromMenu('/')" class="font_bebas logo_c">c</a>
        <a @click="loadPageFromMenu('/brand')" class="no_select font_bebas">Brand</a>
        <a @click="loadPageFromMenu('/fiction')" class="no_select font_bebas">Fiction</a>
        <a @click="loadPageFromMenu('/about')" class="no_select font_bebas">About</a>
        <a style="display:none">v3</a>
      </div>
    </div>
    <div class="page_loader" v-show="loading_leave"></div>
    <div class="app_content" v-if="app_loaded">
      <router-view />
    </div>
    <div class="app_footer">
      <div class="footer_logo"><img :src="require('@/assets/images/ui/crazymage-logo-white.png')" /></div>
      <div class="footer_rs">
        <a class="rs_ico rs_instagram"><img :src="require('@/assets/images/ui/instagram.png')" /></a>
        <a class="rs_ico rs_vimeo"><img :src="require('@/assets/images/ui/vimeo.png')" /></a>
        <a class="rs_ico rs_linkedin"><img :src="require('@/assets/images/ui/linkedin.png')" /></a>
      </div>
      <div class="footer_crazy font_bebas">crazymage</div>
      <div class="footer_contact">
        <a :href="'mailto:' + email">hello@crazymage.fr</a>
      </div>
      <div class="footer_copy font_oswald">&copy; {{current_year}} CRAZYMAGE. All Rights Reserved Credits</div>
    </div>
  </div>
</template>

<script>
/*
<transition name="slide" :duration="{ enter: 0, leave: 800 }"><router-view /></transition>
<div :class="classLoader" ref="loader" v-if="loading_actif">
  <div class="loading_inner">
    <div class="block_foot">
      <svg class="svg_foot" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 800 400">
        <path class="svg_foot_path"></path>
      </svg>
    </div>
  </div>
</div>
*/
// import Spinner from '@/components/Spinner'
// import LoaderTitle from '@/components/LoaderTitle'
import { gsap, Power2 } from 'gsap'
// import Spinner from './components/Spinner.vue'
// <LoaderTitle />
export default {
  components: {},
  name: 'App',
  data: () => ({
    loading_actif: true,
    loading_current: true,
    app_loaded: false,
    current_year: new Date().getFullYear(),
    works_ok: false,
    about_ok: false,
    clients_ok: false,
    startExe: null,
    blockMenu: false,
    email: 'hello@crazymage.fr'
  }),
  beforeRouteEnter (to, from, next) {
    console.log('INSIDE APP - BEFORE ROUTE ENTER')
    let title = ''
    if (to.name === 'home') title = 'crazymage'
    else if (to.name === 'projet') title = 'work'
    else title = to.name
    next(vm => {
      vm.$store.state.loading_title = title
    })
  },
  beforeRouteUpdate (to, from, next) {
    console.log('INSIDE APP - BEFORE ROUTE UPDATE')
    next()
  },
  beforeCreate () {
    console.log('APP - Before created')
    // console.log(this.$route)
    // if (this.$route.name === 'home') this.$store.state.loading_title = 'crazymage'
    // else if (this.$route.name === 'projet') this.$store.state.loading_title = 'work'
    // else this.$store.state.loading_title = this.$route.name
  },
  created () {
    console.log('APP - After created')
    document.documentElement.style.overflow = 'hidden'
    this.getData()
    this.getAbout()
    this.getClients()
    this.$store.state.isMobile = this.isMobile()
  },
  beforeDestroy () {
  },
  mounted: function () {
    this.loader_animation_loop()
    // this.docSize()
    // window.addEventListener('resize', () => {
    //   this.docSize()
    // })
  },
  watch: {
    $route (to, from) {
      if (this.$store.state.loading_first) {
        this.$store.state.loading_title = 'crazymage'
        // if (to.name === 'home') this.$store.state.loading_title = 'crazymage'
        // else if (to.name === 'projet') this.$store.state.loading_title = 'work'
        // else this.$store.state.loading_title = to.name
      }
    },
    loading_open (newLoader, oldLoader) {
      // if (!this.loading_current && newLoader) this.loadingOpen()
      // if (this.loading_current && !newLoader) this.loadingClose()
    },
    loading_leave (newLoader, oldLoader) {
      if (!oldLoader) this.loadingLeave()
    }
  },
  computed: {
    cdn () {
      return this.$store.state.cdn
    },
    loadingTitle () {
      return this.$store.state.loading_title
    },
    classLoader () {
      return this.$store.state.loading_first ? 'loading first' : 'loading'
    },
    loading_open () {
      return this.$store.state.loading_open
    },
    loading_leave () {
      return this.$store.state.loading_leave
    }
  },
  methods: {
    // docSize () {
    //   console.log('doc size')
    //   const vh = window.innerHeight * 0.01
    //   document.documentElement.style.setProperty('--vh', `${vh}px`)
    // },
    loader_animation_loop () {
      const largeur = this.$refs.loader_jpg.clientWidth
      const tps = 0.2
      console.log(largeur)
      /* gsap.timeline()
        .to('.cache_top', { height: '25%', duration: tps * 50 })
        .to('.cache_bottom', { height: '25%', duration: tps * 50 }, 0)
        .to('.cache_left', { width: '25%', duration: tps * 50 }, 0)
        .to('.cache_right', { width: '25%', duration: tps * 50 }, 0) */
      gsap.timeline()
        .to('.bordure_anim_top', { width: '100%', duration: 0.5 })
        .to('.bordure_anim', { backgroundColor: 'red', duration: 0 })
        .to('.bordure_anim_right', { height: '100%', duration: 0.5 })
        .to('.bordure_anim', { backgroundColor: 'yellow', duration: 0 })
        .to('.bordure_anim_bottom', { width: '100%', duration: 0.5 })
        .to('.bordure_anim', { backgroundColor: 'deeppink', duration: 0 })
        .to('.bordure_anim_left', { height: '100%', duration: 0.5 })
        .to('.bordure_anim', { backgroundColor: 'green', duration: 0 })

      gsap.timeline()
        .to('.loader_jpg', { opacity: 1, duration: 0.6 })
        .to('.loader_jpg', { scale: 1, duration: tps * 30 }, 0)
      gsap.timeline({ repeat: -1, repeatDelay: tps })
        .to('.loader_jpg_img', { x: -largeur + 10, duration: 0 }, tps)
        .to('.loader_jpg_img', { x: -largeur * 2 + 20, duration: 0 }, tps * 2)
        .to('.loader_jpg_img', { x: -largeur * 3 + 30, duration: 0 }, tps * 3)
        .to('.loader_jpg_img', { x: -largeur * 4 + 40, duration: 0 }, tps * 4)
        .to('.loader_jpg_img', { x: -largeur * 5 + 50, duration: 0 }, tps * 5)
        .to('.loader_jpg_img', { x: -largeur * 6 + 60, duration: 0 }, tps * 6)
        .to('.loader_jpg_img', { x: -largeur * 7 + 70, duration: 0 }, tps * 7)
        .to('.loader_jpg_img', { x: -largeur * 8 + 80, duration: 0 }, tps * 8)
        .to('.loader_jpg_img', { x: -largeur * 9 + 90, duration: 0 }, tps * 9)
        .to('.loader_jpg_img', { x: -largeur * 10 + 100, duration: 0 }, tps * 10)
        .to('.loader_jpg_img', { x: -largeur * 11 + 110, duration: 0 }, tps * 11)
        .to('.loader_jpg_img', { x: -largeur * 12 + 120, duration: 0 }, tps * 12)
        .to('.loader_jpg_img', { x: -largeur * 13 + 130, duration: 0 }, tps * 13)
    },
    async getData () {
      const self = this
      const works = []
      this.startExe = new Date().getTime()
      this.$db.collection('works').get().then(
        querySnapshot => {
          querySnapshot.docs.forEach(doc => {
            const data = doc.data()
            if (data.display && data.libelle && data.actif && data.folder && data.type) works.push(doc.data())
          })
          self.$store.state.works = works
          self.works_ok = true
          self.checkIfAllDatasLoaded()
          console.log('Works are loaded')
        }
      )
    },
    async getAbout () {
      const self = this
      const about = []
      this.$db.collection('about').get().then(
        querySnapshot => {
          querySnapshot.docs.forEach(doc => {
            const data = doc.data()
            about.push(data)
          })
          console.log(about)
          self.$store.state.about = about
          self.about_ok = true
          self.checkIfAllDatasLoaded()
          console.log('About is loaded')
        }
      )
    },
    async getClients () {
      const self = this
      const clients = []
      this.$db.collection('clients').get().then(
        querySnapshot => {
          querySnapshot.docs.forEach(doc => {
            const data = doc.data()
            clients.push(data)
          })
          console.log(clients)
          self.$store.state.clients = clients
          self.clients_ok = true
          self.checkIfAllDatasLoaded()
          console.log('Clients is loaded')
        }
      )
    },
    checkIfAllDatasLoaded () {
      const self = this
      console.log('check datas are loaded')
      if (this.works_ok && this.about_ok && this.clients_ok) {
        const endExe = new Date().getTime()
        const diff = endExe - this.startExe
        const refTimeValue = 0
        const timeoutValue = diff < refTimeValue ? refTimeValue - diff : 2500
        if (this.$store.state.loading_first) {
          setTimeout(function () {
            // self.loadStartAnimation()
            document.documentElement.style.overflow = 'auto'
            self.loadingClosed()
          }, timeoutValue)
        }
        this.app_loaded = true
      }
    },
    loadingLeave () {
      console.log('LAUNCH LOADING LEAVE')
      // this.$store.state.loading_leave = true
      const self = this
      gsap.timeline().fromTo('.page_loader', {
        height: 0,
        duration: 0
      }, {
        height: '100%',
        duration: 0.9,
        ease: Power2.easeIn,
        onComplete: function () {
          setTimeout(function () { self.$store.state.loading_leave = false }, 400)
        }
      })
    },
    loadingOpened () {

    },
    loadingClosed () {
      const self = this
      setTimeout(function () {
        self.$store.state.loading_open = false
        self.$store.state.loading_direct = false
        self.loading_actif = false
        self.loading_current = false
        self.$store.state.loading_first = false
      }, 100)
      gsap.timeline()
        .to('.nav_left', {
          y: 0,
          duration: 0.6,
          ease: Power2,
          onComplete: function () {
          }
        }, 0)
    },
    loadStartAnimation () {
      const self = this
      const loaderHeight = this.$refs.loader.clientHeight
      // const hauteur = this.$store.state.isMobile ? window.innerHeight : window.innerHeight - 80
      const hauteur = loaderHeight
      // let dH = 150
      // const iWidth = window.innerWidth
      // if (iWidth < 500) {
      //   dH = 280
      // } else if (iWidth < 1000) {
      //   dH = 220
      // }
      gsap.timeline()
        .to('.nav_left', {
          y: 0,
          duration: 0.6,
          ease: Power2,
          onComplete: function () {}
        }, 0)
        .to('.loading', {
          y: -hauteur,
          duration: 0,
          ease: Power2,
          onComplete: function () {
            self.$store.state.loading_open = false
            self.$store.state.loading_direct = false
            setTimeout(function () {
              self.loading_actif = false
              self.loading_current = false
            }, 200)
          }
        }, 0)
      /* gsap.timeline()
        .to('.nav_left', {
          y: 0,
          duration: 0.6,
          ease: Power2,
          onComplete: function () {}
        }, 0)
        .to('.svg_foot_path', {
          // d: "path('M 800 400 Q 400 150 0 400 L 0 0 L 800 0 L 800 0 Z')",
          d: 'path(\'M 800 400 Q 400 ' + dH + ' 0 400 L 0 0 L 800 0 L 800 0 Z\')',
          duration: 0.9,
          ease: Power2
        }, 0.6)
        .to('.svg_foot_path', {
          d: "path('M 800 400 Q 400 400 0 400 L 0 0 L 800 0 L 800 0 Z')",
          duration: 0.6,
          ease: Power2
        }, 1)
        .to('.loading', {
          y: -hauteur,
          duration: 0.9,
          ease: Power2,
          onComplete: function () {
            self.$store.state.loading_first = false
            self.loading_current = false
            setTimeout(function () {
              self.loading_actif = false
            }, 200)
          }
        }, 0.7) */
    },
    loadingOpen () {
      // const self = this
      this.loading_current = true
      this.loading_actif = true
      let dH = 260
      const iWidth = window.innerWidth
      if (iWidth < 500) {
        dH = 350
      } else if (iWidth < 1000) {
        dH = 310
      }
      setTimeout(function () {
        gsap.timeline()
          .fromTo('.svg_foot_path', {
            d: 'path(\'M 800 ' + dH + ' Q 400 400 0 ' + dH + ' L 0 0 L 800 0 L 800 0 Z\')'
          }, {
            d: 'path(\'M 800 ' + dH + ' Q 400 400 0 ' + dH + ' L 0 0 L 800 0 L 800 0 Z\')',
            duration: 0.5,
            ease: Power2
          }, 0)
          .to('.svg_foot_path', {
            d: "path('M 800 400 Q 400 400 0 400 L 0 0 L 800 0 L 800 0 Z')",
            duration: 0.3,
            ease: Power2
          }, 0.4)
          .to('.loading', { y: '0', duration: 0.9, ease: Power2, onComplete: function () {} }, 0.1)
      }, 10)
    },
    loadingClose () {
      console.log('close loader')
      this.$store.state.loading_open = false
      this.$store.state.loading_direct = false
      this.loading_actif = false
      this.loading_current = false
    },
    loadingClose2 () {
      console.log('closing loading')
      const self = this
      const direct = this.$store.state.loading_direct
      const loaderHeight = this.$refs.loader.clientHeight
      // const hauteur = this.$store.state.isMobile ? window.innerHeight : window.innerHeight - 80
      const hauteur = loaderHeight
      let dH = 150
      const iWidth = window.innerWidth
      if (iWidth < 500) {
        dH = 280
      } else if (iWidth < 1000) {
        dH = 220
      }
      console.log('direct')
      console.log(direct)
      if (direct) {
        gsap.timeline()
          .to('.loading', {
            y: -hauteur,
            duration: 0,
            ease: Power2,
            onComplete: function () {
              self.$store.state.loading_open = false
              self.$store.state.loading_direct = false
              setTimeout(function () {
                self.loading_actif = false
                self.loading_current = false
              }, 300)
            }
          }, 0)
      } else {
        gsap.timeline()
          .to('.svg_foot_path', {
            d: 'path(\'M 800 400 Q 400 ' + dH + ' 0 400 L 0 0 L 800 0 L 800 0 Z\')',
            duration: 0.9,
            ease: Power2
          }, 0.6)
          .to('.svg_foot_path', {
            d: "path('M 800 400 Q 400 400 0 400 L 0 0 L 800 0 L 800 0 Z')",
            duration: 0.6,
            ease: Power2
          }, 1)
          .to('.loading', {
            y: -hauteur,
            duration: 0.9,
            ease: Power2,
            onComplete: function () {
              self.$store.state.loading_open = false
              setTimeout(function () {
                self.loading_actif = false
                self.loading_current = false
              }, 300)
            }
          }, 0.7)
      }
    },
    isMobile () {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    },
    loadPageFromMenu (page) {
      // const self = this
      const self = this
      if (this.blockMenu) return false
      this.blockMenu = true
      setTimeout(function () { self.blockMenu = false }, 1500)
      let pageName = page.replace('/', '')
      const currentPage = this.$route.name.toLowerCase()
      if (pageName === '') pageName = 'home'
      if (!this.loading_current && pageName !== currentPage && !this.$store.state.loading_leave) this.$router.push(page)
      /* gsap.timeline()
        .to('.page_loader', {
          height: '100%',
          duration: 0.6,
          ease: Power2,
          onComplete: function () {
            if (!self.loading_current && pageName !== currentPage) self.$router.push(page)
          }
        }, 0) */
    },
    sendmail () {

    }
  }
}
</script>

<style lang="scss">

.page_loader {
  position: fixed;
  will-change: auto;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0%;
  z-index: 2;
  background-color: #141414;
}

@font-face {
  font-family: 'Bebas';
  src: url(assets/fonts/bebas/BebasNeue-Regular.ttf) format("truetype");
}
.font_bebas {
  font-family: 'Bebas', cursive !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Anton';
  src: url(assets/fonts/anton/Anton-Regular.ttf) format("truetype");
}
.font_anton {
  font-family: 'Anton', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Oswald';
  src: url(assets/fonts/oswald/Oswald-ExtraLight.ttf) format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: 'Oswald';
  src: url(assets/fonts/oswald/Oswald-Light.ttf) format("truetype");
  font-weight: 400;
}
.font_oswald {
  font-family: 'Oswald' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body {
  margin:0;
  padding:0;
  min-height: 100vh;
  background: #141414;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-weight:300;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}
// block_inner
.loading {
  color: #fff;
  background: #141414;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh);
  z-index: 999;
  .loading_inner {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .block_inner {
      font-size: 5vw;
    }
    .cache_loader {
      background-color: #141414;
      position: absolute;
      z-index: 2;
      display: none;
      &.cache_top { top: 0; left: 0; width: 100%; height: 35%; }
      &.cache_bottom { bottom: 0; left: 0; width: 100%; height: 35%; }
      &.cache_left { top: 0; left: 0;height: 100%;  width: 35%; }
      &.cache_right { top: 0; right: 0; height: 100%;  width: 35%; }
    }
    .loader_jpg {
      position: absolute;
      opacity: 0;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.95);
      width: 200px;
      height: 200px;
      padding: 5px;
      overflow: hidden;
      img {
        height: 100%;
      }
      .bordure_bg {
        position: absolute;
        background-color: #141414;
        z-index: 2;
        // display: none;
        &.bordure_top { top: 0px; left: 0; width: 100%; height: 5px; }
        &.bordure_bottom { bottom: 0; left: 0; width: 100%; height: 5px; }
        &.bordure_left { top: 0; left: 0;height: 100%;  width: 5px; z-index: 4; }
        &.bordure_right { top: 0; right: 0; height: 100%;  width: 5px; }
      }
      .bordure_anim {
        position: absolute;
        background-color: rgb(62, 107, 255);
        z-index: 3;
        // display: none
        &.bordure_anim_top { top: 0px; left: 0; width: 0%; height: 5px; }
        &.bordure_anim_bottom { bottom: 0; right: 0; width: 0%; height: 5px; }
        &.bordure_anim_left { bottom: 0; left: 0;height: 0%;  width: 5px; z-index: 5;}
        &.bordure_anim_right { top: 0; right: 0; height: 0%;  width: 5px; }
      }
    }
  }
}
.loading_menu {
  color: #fff;
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  height: calc(100vh - 80px);
  z-index: 990;
  transform: translate(0,-100%);
  &.first {
    transform: translate(0,0);
  }
  .loading_inner {
    position: relative;
    height: 100%;
    svg {
      height: 100%;
      width: 100%;
      path {
        fill: blue;
        d: path('M 800 300 Q 400 300 0 300 L 0 0 L 800 0 L 800 300 Z');
      }
    }
    .block_head {
      background: red;
      height: 0;
    }
    .block_main {
      background: #000;
      height: 0;
    }
    .block_foot {
      position: relative;
      height: 100%;
      svg {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        path {
          fill: #141414;
          d: path('M 800 400 Q 400 400 0 400 L 0 0 L 800 0 L 800 200 Z');
        }
      }
    }
  }
}
.navigation {
  position: fixed;
  height: 80px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 991;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #141414;
  a {
    cursor: pointer;
    text-decoration: none;
    font-size: 2em;
    letter-spacing: 0.05em;
    color: #fff;
    text-shadow: 1px 1px 4px #333;
  }
  .nav_left {
    transform: translate(0,-80px);
    //padding-left: 25px;
    //margin-left: -2px;
    padding-left: 40px;
    a {
      font-size:1.2em;
      margin-right:5px;
      padding:4px;
    }
  }
}
.no_select {
   -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.logo_crazy {
   display:none;
   margin-left: -5px;
}
.logo_c {
  display: inline-block;
  position: relative;
  color: #141414 !important;
  margin-right: 20px !important;
  margin-left: -7px;
  &::after {
    content: '';
    background-color: #fff;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -53%);
    z-index: -1;
  }
}
.app_footer {
  padding: 10px 0px 20px;
  //padding-left: 40px;
  display: flex;
  color: #141414;
  flex-direction: column;
  align-items: center;
  background-color: #fefefe;
  .footer_crazy {
    padding-top: 0px;
    font-size: 2em;
    letter-spacing: 0.1em;
    color: #333;
  }
  .footer_logo {
    display: none;
    img {
      width: 100%;
      max-width: 200px;
    }
  }
  .footer_rs {
    display: flex;
    padding-top: 10px;
    .rs_ico {
      cursor: pointer;
      img { width: 25px;}
      margin: 0px 6px;
      &.rs_instagram { margin-left: -5px; }
      &.rs_vimeo { img {width: 24px;} }
      &.rs_linkedin { img {width: 24px;} }
    }
  }
  .footer_copy {
    padding-top: 0px;
    font-size: 0.85em;
  }
  .footer_contact {
    display: flex;
    padding-bottom: 15px;
    a {
      cursor: pointer;
      color: indianred;
      text-decoration: none;
    }
    .footer_contact_row {
      text-align: center;
      font-size: 0.9em;
      padding: 0px 20px;
    }
  }
}

@media screen and (min-width: 42em) {
  .logo_crazy {
    display: inline-block;
  }
  .logo_c {
    display: none;
  }
  .navigation {
    height: 80px;
    a {
      font-size: 2em;
    }
    .nav_left {
      padding-left: 40px;
      //margin-left: -5px;
      a {
        font-size:1.5em;
        margin-right:8px;
        padding:5px;
      }
    }
  }
  .loading {.loading_inner {.loader_jpg { height: 250px; width: 250px; }}}
}
@media screen and (min-width: 60em) {
  .loading {.loading_inner {.loader_jpg { height: 300px; width: 300px; }}}
}
@media screen and (min-width: 72em) {
  .loading {.loading_inner {.loader_jpg { height: 350px; width: 350px; }}}
}
@media screen and (min-width: 110em) {

}

</style>
