<template>
  <div class="home">
    <div class="blocks">

      <div
         class="block section_1"
        >
        <div class="block_contain block_contain_logo">
          <div class="bg_video">
            <div class="bg_video_reveal bg_video_reveal_left"></div>
            <div class="bg_video_reveal bg_video_reveal_right"></div>
            <div class="video_loader_outer"><div class="video_loader"></div></div>
            <div class="bg_video_wrapper">
              <video :src="first_video_src" :onload="firstVideoLoaded()" loop autoplay muted playsinline></video>
            </div>
          </div>
          <div class="bg_title font_bebas">
            <div class="bg_title_container">
              <a @click="$router.push('/brand')">Brand</a> <a>/</a> <a @click="$router.push('/fiction')">Fiction</a>
            </div>
          </div>
          <div class="block_contain_inner">
            <div class="block_logo" style="display:none">
              <img :src="require('@/assets/images/crazymage-logo-white.png')" />
            </div>
          </div>
        </div>
      </div>

      <div
        v-for="(work, index) in works"
        :key="index"
        :class="'block block_ref' + ' section_'+(index+2)"
        :ref="'section_'+(index+2)"
        @click="$router.push('/' + work.type + '/' + work.display)"
        >
        <div class="block_contain block_contain_preview">
          <div class="bg_video bg_video_preview">
            <div class="bg_video_wrapper">
              <video :src="cdn+'/works/' + work.folder + '/' + work.folder + '_home.mp4'" loop autoplay muted playsinline></video>
            </div>
          </div>
          <div class="block_contain_inner">
            <div class="block_libelle">
              <div class="font_anton text_title" v-html="work.libelle.replace('<br/>',' ')"></div>
            </div>
            <div class="block_text_desc">
              <div class="font_oswald text_desc">
                <span v-html="work.type.charAt(0).toUpperCase() + work.type.slice(1) + ' | ' + work.caption_1"></span><br/><span v-html="work.caption_2"></span>
              </div>
              <div class="text_desc_logo" v-if="work.logo"><img :src="cdn + '/images/logos/' + work.logo" /></div>
            </div>
          </div>
        </div>
      </div>

      <div class="block_links" style="display:none">
        <div class="font_bebas block_link" @click="$router.push('/brand')">Brand</div>
        <div class="font_bebas block_link" @click="$router.push('/fiction')">Fiction</div>
      </div>

    </div>
  </div>
</template>

<script>

/* <div class="nav_videos">
  <span
    v-for="(work, index) in works"
    :key="index"
    class="nav_video"
    :ref="'nav_video_' + index"
    @click="navigationVideo('section_' + (index+2))"
  >
  </span>
</div> */
// @ is an alias to /src
// <HelloWorld msg="Welcome to Your Vue.js App"/>
// import HelloWorld from '@/components/HelloWorld.vue'
import _ from 'lodash'
import { gsap, Power4 } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)
export default {
  name: 'Home',
  metaInfo () {
    return {
      title: this.$store.state.meta.basic,
      meta: [
        { name: 'robots', content: this.$store.state.meta.robots },
        { name: 'description', content: this.$store.state.meta.description },
        { property: 'og:title', content: this.$store.state.meta.og_title },
        { property: 'og:site_name', content: this.$store.state.meta.og_site_name },
        { property: 'og:type', content: this.$store.state.meta.og_type },
        { property: 'og:url', content: this.$store.state.meta.og_url },
        { property: 'og:image:type', content: this.$store.state.meta.og_image_type },
        { property: 'og:image', content: this.$store.state.meta.og_image }
      ]
    }
  },
  components: {
    // HelloWorld
  },
  data () {
    return {
      cdn: this.$store.state.cdn,
      works: [],
      scrollActive: true,
      first_video_src: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    window.scrollTo(0, 0)
    next(vm => {
      // vm.$store.state.loading_time_after = 2200
      vm.$store.state.loading_time_after = 0
      vm.$store.state.loading_direct = true
    })
  },
  beforeRouteLeave (to, from, next) {
    console.log('HOME - before route LEAVE')
    this.$store.state.loading_open = true // TO DELETE ?
    this.$store.state.loading_leave = true
    // LOADING TITLE
    if (to.name === 'home') this.$store.state.loading_title = 'crazymage'
    else if (to.name === 'projet') this.$store.state.loading_title = 'work'
    else this.$store.state.loading_title = to.name
    setTimeout(function () {
      next()
    }, this.$store.state.loading_time_before)
  },
  created () {
    const works = this.$store.state.works
    this.works = _.orderBy(_.filter(works, function (w) { return w.actif && w.home_display }), function (e) { return parseInt(e.home_order) }, ['asc'])
    // this.works = []
  },
  /*
  1. les medias a loader avant la fin du loader
  2. les animations d'arrivée
  3. les animations de navigation
  4. les animation de sortie avant la prochaine page
  */
  mounted: function () {
    // this.scrollSection()
    console.log('mounted')
    const self = this
    if (!this.$store.state.loading_first) {
      setTimeout(function () {
        self.$store.state.loading_open = false
      }, this.$store.state.loading_time_after)
    } else {
      // this.$store.state.loading_open = false
    }
    const timeStartAnim = this.$store.state.loading_first ? 2500 : 0
    setTimeout(function () {
      self.first_video_src = self.cdn + '/works/home/home.mp4'
      // self.ready = true
      // self.firstSectionAnim()
    }, timeStartAnim)
    // window.addEventListener('resize', () => {
    //   this.resizeSectionHeight()
    // })
    // this.resizeSectionHeight()
  },
  methods: {
    navigationVideo (item) {
      const offset = this.$refs[item][0].offsetTop - 80
      console.log(offset)
      gsap.to(window, { duration: 2, scrollTo: offset })
    },
    resizeSectionHeight () {
      const vh = window.innerHeight - 80
      // this.$refs.section_1.style.setProperty('height', `${vh}px`)
      gsap.to('.block', { height: vh + 'px', duration: 0 })
    },
    firstVideoLoaded () {
      const timeToWait = this.$store.state.loading_first ? 2500 : 200
      setTimeout(function () {
        gsap.timeline()
          .to('.video_loader_outer', { opacity: 0 })
          .to('.bg_video_reveal_left', { width: 0, duration: 1, ease: Power4.easeInOut }, 0)
          .to('.bg_video_reveal_right', { width: 0, duration: 1, ease: Power4.easeInOut }, 0)
          .to('.bg_video_wrapper', { scale: 1, duration: 1, ease: 'linear' }, 0)
          .to('.bg_title_container', { opacity: 1, duration: 0.5, ease: 'linear' }, 0.7)
      }, timeToWait)
    },
    firstSectionAnim () {
      gsap.timeline()
        .to('.bg_title_container', { opacity: 1, duration: 0.5, ease: 'linear' }, 2)
    },
    scrollSection () {
      const self = this
      const panels = gsap.utils.toArray('.block')
      // const scrollTween = this.scrollTween
      panels.forEach((panel, i) => {
        ScrollTrigger.create({
          trigger: panel,
          start: 'top bottom',
          end: 'bottom',
          // onToggle: self => self.isActive && !scrollTween && _.goToSection(i),
          onToggle: function (el) {
            if (el.isActive && self.scrollActive) {
              self.scrollActive = false
              self.goToSection(i)
            }
            // self.goToSection(i)
          },
          scrub: true,
          pin: false,
          pinSpacing: false,
          markers: false
        })
      })
      // ScrollTrigger.create({
      //   start: 0,
      //   end: 'max',
      //   snap: 1 / (panels.length - 1)
      // })
    },
    goToSectionOld (i) {
      // const self = this
      console.log('enter section !')
      console.log(i)
      console.log(window.innerHeight)
      gsap.to(window, {
        // scrollTo: { y: i * innerHeight, autoKill: false },
        scrollTo: { y: i * innerHeight },
        duration: 1,
        onComplete: function () {},
        overwrite: true
      })
    },
    goToSection (i) {
      const self = this
      console.log(i)
      const hauteur = window.innerHeight
      const hauteurF = (hauteur * i) + (80 * i) + 1
      console.log(hauteurF)
      // this.scrollActive = true
      gsap.to(window, {
        scrollTo: { y: hauteurF, autoKill: false },
        duration: 1,
        onComplete: function () { self.scrollActive = true }
        // overwrite: false
      })
    }
  },
  computed: {
    // works () {
    //   const works = this.$store.state.works
    //   return _.orderBy(_.filter(works, function (w) { return w.actif && w.home_display }), function (e) { return parseInt(e.home_order) }, ['asc'])
    // },
    // cdn () {
    //   return this.$store.state.cdn
    // }
  }
}
</script>

<style lang="scss" scoped>
.home {
}
.nav_videos {
  position: fixed;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  z-index: 990;
  .nav_video {
    position: relative;
    display: block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-top: 0.8em;
    border: 1px solid #fff;
    cursor: pointer;
    &:nth-child(1) {
      margin-top: 0;
    }
    &::after {
      content: '';
      position: absolute;
      background: #fff;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 65%;
      width: 65%;
      border-radius: 50%;
    }
  }
}
.blocks {
  display:flex;
  flex-direction: column;
  //padding: 0 40px;
  padding-top: 80px;
  &.blocks_preview {
    padding-left: 0;
    padding-right: 0;
  }
  //scroll-snap-type: y mandatory;
}
.block {

  width:calc(100%);
  //flex-basis: calc(100%);
  height:calc(100vh - 80px);
  transition: height 0.4s linear;
  &:nth-child(1) {
    width: calc(100% - 50px);
    padding: 0 25px;
  }

  &.block_ref {
    cursor: pointer;
  }
  //height:100vh;
  //scroll-snap-align: start;

  .block_contain {

    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    position:relative;

    &.block_contain_preview {
      justify-content: flex-end;
      align-items: flex-end;
    }

    .bg_video {
      // position:absolute;
      // top:0px;
      // left:0px;
      position: relative;
      width:100%;
      height:80%;
      z-index:0;
      overflow: hidden;
      &.bg_video_preview {
        position:absolute;
        top:0px;
        left:0px;
        width:100%;
        height:100%;
      }
      .bg_video_reveal {
        position: absolute;
        top: 0;
        width: 50%;
        height: 100%;
        z-index: 2;
        background: #141414;
        // background: red;
        &.bg_video_reveal_left {
          //background: red;
          left: 0;
        }
        &.bg_video_reveal_right {
          //background: green;
          right: 0;
        }
      }
      .video_loader_outer {
        position: absolute;
        z-index: 3;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        align-items: center;
        opacity: 1;
        top: 0;
        left: 0;
      }
      .video_loader,
      .video_loader:before,
      .video_loader:after {
        border-radius: 50%;
        width: 2.5em;
        height: 2.5em;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation: load7 1.8s infinite ease-in-out;
        animation: load7 1.8s infinite ease-in-out;
      }
      .video_loader {
        color: #333;
        font-size: 6px;
        margin-top: -2.5em;
        // margin: 80px auto;
        position: relative;
        text-indent: -9999em;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
      }
      .video_loader:before,
      .video_loader:after {
        content: '';
        position: absolute;
        top: 0;
      }
      .video_loader:before {
        left: -3.5em;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
      }
      .video_loader:after {
        left: 3.5em;
      }
      @-webkit-keyframes load7 {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }
      @keyframes load7 {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }
      .bg_video_wrapper {
        height: 100%;
        overflow: hidden;
        padding: 0;
        position: relative;
        transform: scale(1.1);
        z-index: 1;
        video {
          object-fit: cover;
          object-position: center;
          width: 100vw;
          height: 100vh;
          top: 0;
          left: 0;
        }
      }
    }
    .block_contain_inner {
      z-index:1;
      color:#fff;
      padding-bottom: 4em;
      padding-right: 2em;
      opacity:0.95;
      .block_text_desc {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .text_desc_logo {
        padding-left: 15px;
        //display: flex;
        img {
          width: 60px;
          height: auto;
          max-width: 60px;
        }
      }
      .text_desc {
        font-size:0.9em;
        letter-spacing: 0.02em;
        text-align:right;
        padding-left:20px;
        line-height: 1.3em;
      }
      .block_libelle {
        display:flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content:flex-end;
        cursor: pointer;
        .text_title {
          color:#fff;
          font-size:2.5em;
          line-height: 1.2em;
          letter-spacing: 0.01em;
          text-align: right;
          margin-right: -2px;
          position: relative;
          /* &::after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: -1%;
            // transform: translate(-50%,0);
            background-color: red;
            width: 0%;
            height: 35%;
            z-index: -1;
            opacity: 0.6;
            transition: all 0.8s cubic-bezier(.43,1.1,.74,.98);
          }
          &:hover {
            &::after {
              width: 102%;
            }
          } */
        }
      }
    }
    .bg_title {
      flex-grow: 1;
      display: flex;
      align-items: center;
      .bg_title_container {
        //font-size: 10em;
        //line-height: 0.85em;
        transition: color 0.5s linear 0s;
        opacity: 0;
        text-align:center;
        //&:hover {
        //  color: #555;
        //}
        a {
          display: inline-block;
          cursor: pointer;
          font-size: 15vw;
          line-height: 15vw;
          letter-spacing: 0.02em;
          transition: color 0.5s linear 0s;
          &:nth-child(2) {
            color: #141414;
            position: relative;
            &::after {
              content: '';
              position: absolute;
              background: #fff;
              height: 2vw;
              width: 2vw;
              border-radius: 50%;
              top: 45%;
              left: 50%;
              transform: translate(-50%,-60%);
            }
            //margin-left: -0.04em;
          }
          &:nth-child(3) {
            //margin-right: -0.00em;
          }
          //&:hover {
          //  color: #fff;
          //}
        }
      }
    }

    &.block_contain_logo {
      // align-items: flex-start;
      // justify-content: center;
      .block_contain_inner {
        padding:0;
        .block_logo {
          text-align: center;
          img {
            width: 90%;
            max-width: 200px;
          }
        }
      }
    }

  }
}
.block_links {
  display:flex;
  flex-wrap:nowrap;
  flex-direction: column;
  justify-content:space-between;
  min-height:300px;
  .block_link {
    cursor:pointer;
    font-size:3em;
    color:#fff;
    font-weight:400;
    justify-content:center;
    align-items:center;
    flex-grow:1;
    display:flex;
    background-color:#000;
    /*&:nth-child(2) {
      background-color:#fff;
      color:#000;
    }*/
    &:hover {
      background-color:#fff;
      color:#000;
    }
  }
}

@media screen and (min-width: 42em) {
  .block {
    &:nth-child(1) {
      width: calc(100% - 80px);
      padding: 0 40px;
    }
    .block_contain {
      .bg_video {
        height: 75%;
      }
      .block_contain_inner {
        padding-bottom: 2em;
        .block_libelle {
          .text_title {
            font-size:3em;
            line-height: 1.2em;
          }
        }
      }
      .bg_title {
        .bg_title_container {
          a {
            font-size: 17.5vw;
            line-height: 17.5vw;
            letter-spacing: 0.00em;
          }
        }
      }
      &.block_contain_logo {
        .block_contain_inner {
          .block_logo {
            img {
              max-width: 300px;
            }
          }
        }
      }
    }
  }
  .block_links {
    min-height:250px;
    flex-direction: row;
    .block_link {
      font-size:6.5em;
    }
  }
}

@media screen and (min-width: 60em) {
  .block {
    .block_contain {
      .bg_video {
        height: 70%;
      }
      .bg_title {
        .bg_title_container {
          a {
            font-size: 18.5vw;
            //line-height: 18.5vw;
            letter-spacing: 0.00em;
            padding-top: 0.1em;
          }
        }
      }
    }
  }
  .block_links {
    min-height:350px;
    flex-direction: row;
    .block_link {
      font-size:8.5em;
    }
  }
}

@media screen and (min-width: 72em) {
  .block {
    .block_contain {
      .bg_title {
        .bg_title_container {
          a {
            font-size: 19vw;
            line-height: 19vw;
            letter-spacing: 0.005em;
            margin-bottom: -0.1em;
            padding-top: 0em;
          }
        }
      }
    }
  }
  .block_links {
    min-height:400px;
    flex-direction: row;
    .block_link {
      font-size:10em;
    }
  }
}

@media screen and (min-width: 110em) {
  .block {
    .block_contain {
      .bg_title {
        .bg_title_container {
          a {
            margin-bottom: -0.1em;
            font-size: 19vw;
            line-height: 19vw;
            letter-spacing: 0.017em;
          }
        }
      }
    }
  }
}

</style>
